import React from "react";
import styled from "styled-components";
import { Typography } from "antd";
import { StyledText } from "../LayoutComponent";
import CaseAssignComponent from "./CaseAssignComponent";

const { Text, Title } = Typography;

const withDisable = (WrapperComponent) => {
    return ({ isDisabled, ...props }) => {

        const StyledComponent = styled.span`
            display: flex;
            gap: 10px;
            ${ isDisabled ? `pointer-events: none; opacity: 0.5;` : `` }
        `;

        return <StyledComponent><WrapperComponent { ...props }/></StyledComponent>
    };
}

const StyledHeaderWrapper = styled.div`
  background-color: #ffffff;
  padding: 10px;
  width: 100%;
`;

const StyledSubTitleWrapper = styled.div`
  display: flex;
  gap: 30px;
  width:100%;
`;

const Assignment = styled.div`
  display: flex;
  gap: 10px;
  margin-left: auto;
  text-transform: capitalize;
`

const AssignComponent = withDisable(CaseAssignComponent);

const MessageHeaderComponent = ({
    ticketId,
    caseId,
    physician,
    patient,
    assignedRep,
    userDetails,
  }) => {
    return (
        <StyledHeaderWrapper>
            <Title level={ 4 }>Case Id : { caseId }</Title>
            <StyledSubTitleWrapper>
                <Text>
                    Physician : <StyledText>{ physician.name }</StyledText>
                </Text>
                <Text>
                    Patient : <StyledText>{ patient }</StyledText>
                </Text>
                <Assignment>
                    <AssignComponent currentAdminInfo={ userDetails }
                                     ticketId={ ticketId }
                                     assignedRep={ assignedRep }
                                     isDisabled={ !ticketId }
                    />
                </Assignment>
            </StyledSubTitleWrapper>
        </StyledHeaderWrapper>
    );
};

export default React.memo(MessageHeaderComponent);
