import { LoadingOutlined } from "@ant-design/icons";
import { message, Select, Spin } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";

import { updateTicket } from "../../api/CaseTicketsAPI";
import { getUsersListByStatus } from "../../api/UserTypeAPI";

const REQUEST_CANCELLED = 'request cancelled';

const getAdminsDropDownList = (currentAdmin, adminsList) => (
    [
        currentAdmin,
        ...adminsList.map(admin => dropDownItem(admin))
    ]
);

const dropDownItem = (admin, prefix, symbolPrefix, symbolSuffix) => {

    const labelWrapper = (content) =>
        prefix && symbolPrefix
            ? `${ prefix } ${ symbolPrefix } ${ content } ${ symbolSuffix }` :
            content;

    return {
        value: admin._id,
        label: `${ labelWrapper(`${ admin.firstName } ${ admin.lastName }`) }`
    }
}

const CaseAssignComponent = ({ currentAdminInfo, ticketId, assignedRep }) => {
    const [ adminsDropDown, setAdminsDropDown ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [ admins, setAdmins ] = useState([])
    const [ adminByRepId, setAdminByRepId ] = useState(null)

    const getAdminsList = (physicians) => {
        const list = {};
        physicians.forEach((physician) => {
            const { _id } = physician;
            list[_id] = physician;
        });
        return list;
    }

    const areFetchedAdmins = React.useRef(false); //avoid multiple fetch
    const isUnmounted = React.useRef(false); //avoid leak memory

    const fetchAdmins = useCallback(async (axiosConfig) => {

        if (areFetchedAdmins.current) {
            return;
        }
        areFetchedAdmins.current = true;

        try {
            const { data } = await getUsersListByStatus('active', axiosConfig)

            if (!data.payload?.length) {
                message.warn("No active admin users found");
                return
            }

            let assignedToTicketAdmin = null;
            let currentAdmin = null;

            const otherAdmins = data.payload.reduce((result, admin) => {
                if (admin.email === assignedRep?.email) {
                    assignedToTicketAdmin = admin;
                }

                if (admin.email === currentAdminInfo.userEmail) {
                    currentAdmin = admin;
                    return result;
                }

                result.push(admin);
                return result;
            }, []);

            if (assignedToTicketAdmin) setAdminByRepId(assignedToTicketAdmin._id);
            setAdminsDropDown(getAdminsDropDownList(
                dropDownItem(currentAdmin, 'me', '(', ')'),
                otherAdmins
            ))
            setAdmins(getAdminsList(data.payload));
        } catch (error) {
            if (error.message === REQUEST_CANCELLED) {
                console.log(error.message);
                return;
            }
            areFetchedAdmins.current = false;
            console.log("Error fetching admin users", error);
            message.error("Unable to fetch admin users");
        } finally {
            if (!isUnmounted.current) {
                setLoading(false);
            }
        }
    },[assignedRep?.email, currentAdminInfo.userEmail]);


    useEffect(() => {
        const source = axios.CancelToken.source()
        fetchAdmins({ cancelToken: source.token });
        return () => {
            isUnmounted.current = true;
            source.cancel(REQUEST_CANCELLED);
        };
    }, [fetchAdmins])

    const handleTakeAssignment = async (adminId) => {
        const admin = admins[adminId];
        setLoading(true);
        try {
            await updateTicket({ rep: { userEmail: admin.email }, ticketId })
            setAdminByRepId(admin._id);
            message.success(`Ticket has been assigned to ${ admin.firstName } ${ admin.lastName } successfully`)
        } catch (e) {
            message.error('Error taking assignment of ticket')
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            Assigned to: <Spin spinning={ loading } indicator={ <LoadingOutlined/> }>

            <Select showSearch
                    optionFilterProp="label"
                    options={ adminsDropDown }
                    onChange={ handleTakeAssignment }
                    value={ adminByRepId }
                    placeholder="Please select"
                    style={ { width: 170, marginTop: -5 } }>
            </Select>
        </Spin>
        </>
    )
};

export default CaseAssignComponent;
